<template>
  <div class="app-container">
    <CrudTable entity="Restaurant" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '../../../utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name', label: 'common.restaurant' },
        { field: 'code', label: 'common.code' },
        { field: 'priceCategoryName', label: 'common.priceCategory' },
        'phone',
        'email',
        {
          field: 'toGoConceptYN',
          label: 'common.toGo',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'takeOutAndDeliveryConceptYN',
          label: 'common.takeOutAndDeliveryConcept',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  methods: {
    generateSummary({ name }) {
      return `${name}`;
    }
  }
};
</script>
